
import {
  BrowserRouter,
    createBrowserRouter,
    HashRouter,
    Route,
    Routes
  } from "react-router-dom";
import Dashboard from "Views/Dashboard/Dashboard";
import SignInPage from "Views/SignInPage/SignInPage";
import SignUpPage from "Views/SignUpPage/SignUpPage";


export const PageRoutes: React.FC = () => {
  return (
    <HashRouter
                             
    >

<Routes>
<Route path="/" element={<Dashboard />} />
<Route path="/signin"  element={<SignInPage />} />
<Route path="/signup" element={<SignUpPage />} />
</Routes>
    </HashRouter>
  )
}
